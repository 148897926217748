import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
import SEO from '../components/seo'
import { graphql } from 'gatsby'

import { makeTitle } from '../functions/Utils'
import MultiComponent from '../components/MultiComponent';
const IndexPage = ({data}) => {
  
  const { contentfulPage, contentfulPage: { name } } = data;

  const one = contentfulPage.content[0]
  const two = contentfulPage.content[1]
  const three = contentfulPage.content[2]
  const four = contentfulPage.content[3]
  const five = contentfulPage.content[4]


  return(
  
  <Layout
    backgroundImage={contentfulPage.image}
  
  >
    <SEO title={makeTitle(name)} />
    <section id="banner">
      <div className="inner">
        <h2>{one.header}</h2>
        <p>{one.subHeader}</p>

      </div>

      { MultiComponent(one.content) }


      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id={two.name} className="wrapper style1 special">
      <div className="inner">
        <ul className="icons major">

        { MultiComponent(two.content) }

        </ul>
        <header className="major">
          <h2>
            {two.header} <br /> {two.subHeader}
          </h2>
        </header>

      </div>
    </section>

    <section id={three.name} className="wrapper alt style2">


    { MultiComponent(three.content) }

    </section>

    <section id={four.name} className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>{four.header}</h2>
          <p>
            {four.subHeader}
          </p>
        </header>
        <ul className="features">
        { MultiComponent(four.content) }
        </ul>
      </div>
    </section>

    <section id={five.name} className="wrapper style4 special " style={{minHeight: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <div className="inner major">
        <header style={{ margin: '0 auto'}}>
          <h2 style={{marginBottom: '0.2rem'}}>{five.header}
          </h2>
          <h2 style={{color: '#03e2ff'}}>
            {five.subHeader}
          </h2>
        </header>

        { MultiComponent(five.content) }

      </div>
    </section>
  </Layout>
)};

export const query = graphql`
  query HomePageQuery {
      contentfulPage(url: {eq: "/"}) {
        id
        name
        content {
          ... on ContentfulSection {
            name
            header
            subHeader
            id
            content {
              ... on ContentfulButton {
                id
                content
                linksTo
              }
              ... on ContentfulButtonScroll {
                id
                content
                scrollTo
              }
              ... on ContentfulFeature {
                id
                header
                description
                image {
                  file {
                    url
                  }
                  description
                }
              }
              ... on ContentfulIcon {
                id
                colour
                iconCode
              }
              ... on ContentfulService {
                id
                header
                iconCode
                services:content
              }
            }
          }
        }
        image {
          file {
            url
          }
        }
      }
  }
`

export default IndexPage;
